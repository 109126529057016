import React from 'react';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-black rounded-lg shadow-lg p-4 w-96 max-w-md">
        <div className="flex justify-between items-center border-b pb-2">
          <h2 className="text-lg font-bold text-green-400">Prediksi Saya</h2>
          <button onClick={onClose} className="text-red-500 text-lg">&times;</button>
        </div>
        <div className="mt-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
