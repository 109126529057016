import React, { useState, useEffect } from 'react';
import logoDen from '../assets/logo/logoden.png';
import PredictionForm from '../component/PredictionForm';
import ViewPredictions from '../component/ViewPredictions';
import Modal from '../component/Modal';

const Main = () => {
  const [user, setUser] = useState(null);
  const [round, setRound] = useState(null);
  const [teams, setTeams] = useState([]);
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [serverTime, setServerTime] = useState('');
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const roundTimestamps = {
    1: 1719676800,
    2: 1720195200,
    3: 1720551600,
  };

  const calculateTimeLeft = (targetTimestamp) => {
    const difference = targetTimestamp * 1000 - new Date().getTime();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        H: Math.floor(difference / (1000 * 60 * 60 * 24)),
        J: Math.floor((difference / (1000 * 60 * 60)) % 24),
        M: Math.floor((difference / 1000 / 60) % 60),
        S: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(roundTimestamps[round]));

  useEffect(() => {
    const tg = window.Telegram.WebApp;
    console.log(tg);
    const initialize = async () => {
      if (tg) {
        tg.expand();

        const initDataUnsafe = tg.initDataUnsafe;
        if (initDataUnsafe && initDataUnsafe.user) {
          const telegramId = initDataUnsafe.user.id.toString();
          const userName = initDataUnsafe.user.username || initDataUnsafe.user.first_name || "Prajurit";
          await fetchRoundAndTeams();
          await checkUser(telegramId, userName);
        } else {
          console.log('no detect tg');
        }
        tg.ready();
      } else {
        console.log('error cause not tg ?');
      }

//      const telegramId = '123456';
  //    const userName = 'kadal';
    //  await fetchRoundAndTeams();
     // await checkUser(telegramId, userName);
    };

    initialize();
  }, []);

  useEffect(() => {
    if (user && round !== null) {
      setIsEligible(user.eligible_for_round === round);
    }
  }, [user, round]);

  useEffect(() => {
    if (round !== null) {
      const updateTimer = () => {
        const newTimeLeft = calculateTimeLeft(roundTimestamps[round]);
        setTimeLeft(newTimeLeft);
        if (Object.keys(newTimeLeft).length === 0) {
          setIsTimeUp(true);
        }
      };

      updateTimer();
      const timer = setInterval(updateTimer, 1000);

      return () => clearInterval(timer);
    }
  }, [round]);

  useEffect(() => {
    const fetchServerTime = async () => {
      try {
        const response = await fetch('https://api.wolfible.net/time.php'); // Ganti dengan URL yang benar
        const data = await response.json();
        console.log(data);
        setServerTime(data.server_time);
      } catch (error) {
        console.error('Error fetching server time:', error);
      }
    };

    fetchServerTime();
  }, []);

  const fetchRoundAndTeams = async () => {
    try {
      const response = await fetch('https://api.wolfible.net/get_round_and_teams');
      const data = await response.json();

      if (response.ok) {
        setRound(data.round);
        setTeams(data.teams);
      } else {
        showMessage('Error fetching round and teams information');
      }
    } catch (error) {
      showMessage(`Error: ${error.message}`);
    }
  };

  const checkUser = async (telegramId, userName) => {
    try {
      const response = await fetch('https://api.wolfible.net/cekuser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          telegram_id: telegramId,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setUser(data.user);
      } else if (response.status === 404) {
        registerUser(telegramId, userName);
      } else {
        showMessage(data.error);
      }
    } catch (error) {
      showMessage(`Error: ${error.message}`);
    }
  };

  const registerUser = async (telegramId, userName) => {
    if (userName) {
      try {
        const response = await fetch('https://api.wolfible.net/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams({
            telegram_id: telegramId,
            name: userName,
          }),
        });

        const data = await response.json();

        if (response.ok) {
          showMessage('User registered successfully!');
          setUser(data.user);
        } else {
          showMessage(data.error);
        }
      } catch (error) {
        showMessage(`Error: ${error.message}`);
      }
    } else {
      showMessage('Registration cancelled.');
    }
  };

  const getRoundDescription = (round) => {
    switch (round) {
      case 1:
        return '16 Besar';
      case 2:
        return '8 Besar';
      case 3:
        return '4 Besar';
      case 4:
        return 'Selesai';
      default:
        return 'Tidak Diketahui';
    }
  };

  const showMessage = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage('');
    }, 3000);
  };

  const timerComponents = [];

  Object.keys(timeLeft).forEach(interval => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div className="min-h-screen flex justify-center bg-black overflow-x-hidden">
      <div className="p-2 w-96 max-w-md bg-black">
        <div className="flex items-center justify-between mb-1 mt-4">
          <span className="font-bold text-2xl gradient-text ">{user ? user.name : 'Prajurit'}</span>
          <span className="font-bold text-xl ">{getRoundDescription(round)}</span>
        </div>
        <div className='text-center'>
          <div className='mt-4 flex justify-center'>
            <img src={logoDen} alt='logo' className=' w-24 h-24'></img>
          </div>
          <h1 className='text-lg mt-4'>Euro 2024 ⚽️</h1>
          <p className='text-xs text-gray-500 mt-2'>Bukan diperuntukan untuk JUDI, Tebak Juara Euro 2024!</p>
          <p className='text-xs text-white mt-2'>Waktu Batas untuk Memilih</p>
          <div className="text-center text-yellow-400 font-bold mt-4">
            {timerComponents.length ? timerComponents : <span>Time's up!</span>}
          </div>
        </div>

        {user && (
          <>
            {round === 4 && isEligible ? (
              <div className="text-center text-green-500 font-bold">Selamat Kamu Menang!</div>
            ) : (
              <>
                {isEligible ? (
                  isTimeUp ? (
                    <div className="text-center text-yellow-500 font-bold">Waktu sudah habis, semoga menang!</div>
                  ) : (
                    <PredictionForm user={user} round={round} teams={teams} fetchRoundAndTeams={fetchRoundAndTeams} />
                  )
                ) : (
                  <div className="text-center text-red-500">Kamu sudah kalah</div>
                )}

                <div className='flex justify-around gap-4 mt-4'>
                <button
                  className="btnPAW w-full"
                  onClick={() => setIsModalOpen(true)}
                >
                  Prediksi Ku
                </button>
                <button
                  className="btnPAW w-full"
                  onClick={() => setIsInfoModalOpen(true)}
                >
                  Info
                </button>
                </div>
             
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                  <ViewPredictions user={user} />
                </Modal>
                <Modal isOpen={isInfoModalOpen} onClose={() => setIsInfoModalOpen(false)}>
                  <div className='text-justify mt-5'>
                    <p className='text-xs'>✅ 16 Besar : Pilih 8 Minimal 5 Team Benar</p>
                    <p className='text-xs'>✅ 8 Besar  : Pilih 4 Minimal 3 Team Benar</p>
                    <p className='text-xs'>✅ 4 Besar  : Pilih 1 Minimal 1 Team Benar</p>
                  </div>
                </Modal>
              </>
            )}
          </>
        )}
        {message && <div className="text-green-500 mt-2 font-bold fixed top-5 p-2 bg-black rounded-xl">{message}</div>}
      </div>
    </div>
  );
}

export default Main;
