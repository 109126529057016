import React, { useEffect, useState } from 'react';
import './App.css';
import Main from './page/main';
import { Routes, Route } from 'react-router-dom';

function App() {
 
  return (
  <div>
    <Routes>
      <Route path="/" element={<Main/>} />
    </Routes>
  </div>
  );
}

export default App;