import React, { useEffect, useState } from 'react';

const ViewPredictions = ({ user }) => {
  const [predictions, setPredictions] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchPredictions();
  }, []);

  const fetchPredictions = async () => {
    try {
      const response = await fetch('https://api.wolfible.net/getprediction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          user_id: user.id,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setPredictions(data.predictions);
      } else {
        setMessage(data.error);
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  
  const getRoundDescription = (round) => {
    switch (round) {
      case 1:
        return '16 Besar';
      case 2:
        return '8 Besar';
      case 3:
        return '4 Besar';
      case 4:
        return 'Selesai';
      default:
        return 'Tidak Diketahui';
    }
  };

  return (
    <div>
      {predictions.length > 0 ? (
        predictions.map((prediction, index) => (
          <div key={index} className="mb-4 p-4 border rounded-md shadow-md">
            <h4 className="text-lg text-green-400 font-bold">Round {getRoundDescription(prediction.round)}</h4>
            <p className="text-sm">Teams: <span className='text-yellow-400 font-bold'>{JSON.parse(prediction.teams).join(', ')}</span> </p>
          </div>
        ))
      ) : (
        <p className="text-sm text-red-500">Belum Memilih.</p>
      )}
      {message && <div className="text-red-500 mt-2">{message}</div>}
    </div>
  );
};

export default ViewPredictions;
