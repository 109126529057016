import React, { useState } from 'react';

function PredictionForm({ user, round, teams, fetchRoundAndTeams }) {
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [message, setMessage] = useState('');
 

  

  const handleCheckboxChange = (event) => {
    const team = event.target.value;
    if (event.target.checked) {
      setSelectedTeams([...selectedTeams, team]);
    } else {
      setSelectedTeams(selectedTeams.filter((t) => t !== team));
    }
  };

  const savePrediction = async () => {
    console.log(user)
    const requiredTeamCount = round === 1 ? 8 : round === 2 ? 4 : 1;
    if (selectedTeams.length !== requiredTeamCount) {
      setMessage(`Wajib Pilih ${requiredTeamCount} Team`);
      return;
    }

    try {
      const response = await fetch('https://api.wolfible.net/prediction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          telegram_id: user.telegram_id,
          teams: JSON.stringify(selectedTeams),
          round: round,
        }),
      });

      const data = await response.json();

      if (response.status === 200) {
        setMessage('Prediksi sudah di simpan');
      } else {
        setMessage('Kamu sudah memilih');
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  const getRoundDescription = (round) => {
    switch (round) {
      case 1:
        return '16 Besar';
      case 2:
        return '8 Besar';
      case 3:
        return '4 Besar';
      case 4:
        return 'Selesai';
      default:
        return 'Tidak Diketahui';
    }
  };

  return (
    <div className='mt-4'>
       <div className='flex justify-between items-center'>
            <div className='text-start'>
            <h3 className="text-sm mb-2">Kualifikasi {getRoundDescription(round)}</h3>
            </div>
            <div className='text-start'>
            <h3 className="text-sm font-bold mb-2">Pilih : ✅</h3>
            </div>
       </div>

      <div className="grid grid-cols-3 gap-4 md:grid-cols-3 lg:grid-cols-4 mb-4 mt-4">
        {teams.map((team) => (
          <div key={team.id} className="border rounded-lg shadow-md p-1 relative">
            <div className="flex items-center flex-col">
                <div>
                        <label htmlFor={team.id} className="block text-sm text-white font-bold">
                        {team.name}
                    </label>
                </div>

                <div className='flex justify-around gap-4 items-center mt-1'>
                    <p style={{fontSize:"8px"}}>Pilih</p>
                    <input
                type="checkbox"
                id={team.id}
                name="team"
                value={team.name}
                className="h-3 w-3 text-green-600 border-gray-300 rounded"
                onChange={handleCheckboxChange}
              />
                </div>
            
       
            </div>
          </div>
        ))}
      </div>
        
    <div className='text-center'>
    <button
        className="btnPAW mb-4"
        onClick={savePrediction}
      >
        Kirim Prediksi
      </button>
      {message && <div className="text-green-500 mt-2 font-bold fixed top-5 p-2 bg-black rounded-xl">{message}</div>}
    </div>
     
    </div>
  );
}

export default PredictionForm;
